@font-face {
    font-family: MontserratBlack;
    src: url('./assets/fonts/montserrat/Montserrat-Black.ttf');
}

@font-face {
    font-family: MontserratBlackItalic;
    src: url('./assets/fonts/montserrat/Montserrat-BlackItalic.ttf');
}

@font-face {
    font-family: MontserratBold;
    src: url('./assets/fonts/montserrat/Montserrat-Bold.ttf');
}

@font-face {
    font-family: MontserratBoldItalic;
    src: url('./assets/fonts/montserrat/Montserrat-BoldItalic.ttf');
}

@font-face {
    font-family: MontserratExtraBold;
    src: url('./assets/fonts/montserrat/Montserrat-ExtraBold.ttf');
}

@font-face {
    font-family: MontserratExtraBoldItalic;
    src: url('./assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf');
}

@font-face {
    font-family: MontserratExtraLight;
    src: url('./assets/fonts/montserrat/Montserrat-ExtraLight.ttf');
}

@font-face {
    font-family: MontserratExtraLightItalic;
    src: url('./assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf');
}

@font-face {
    font-family: MontserratItalic;
    src: url('./assets/fonts/montserrat/Montserrat-Italic.ttf');
}

@font-face {
    font-family: MontserratLight;
    src: url('./assets/fonts/montserrat/Montserrat-Light.ttf');
}

@font-face {
    font-family: MontserratLightItalic;
    src: url('./assets/fonts/montserrat/Montserrat-LightItalic.ttf');
}

@font-face {
    font-family: MontserratMedium;
    src: url('./assets/fonts/montserrat/Montserrat-Medium.ttf');
}

@font-face {
    font-family: MontserratMediumItalic;
    src: url('./assets/fonts/montserrat/Montserrat-MediumItalic.ttf');
}

@font-face {
    font-family: MontserratRegular;
    src: url('./assets/fonts/montserrat/Montserrat-Regular.ttf');
}

@font-face {
    font-family: MontserratSemiBold;
    src: url('./assets/fonts/montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: MontserratSemiBoldItalic;
    src: url('./assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
    font-family: MontserratThin;
    src: url('./assets/fonts/montserrat/Montserrat-Thin.ttf');
}

@font-face {
    font-family: MontserrathinItalic;
    src: url('./assets/fonts/montserrat/Montserrat-ThinItalic.ttf');
}

* {
    font-family: MontserratRegular;
}

body {
    background-color: #FAFAFA;
}

.page-wrapper {
    overflow-x: clip;
}

.bold {
    font-family: MontserratBold;
}

.medium-font {
    font-family: MontserratMedium;
}

section .title {
    font-family: MontserratMedium;
    font-size: 56px;
}

@media screen and (max-width: 575px) {
    section .title {
        font-size: 35px;
    }
}