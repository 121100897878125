.panarea-section {
    padding-bottom: 100px;
    margin-bottom: 50px;
}

.panarea-section .text {
    padding: 35px 0 0 45px;
    position: relative;
}

.panarea-section .text .corner {
    position: absolute;
    top: 0;
    left: 10px;
    width: 60px;
    height: 60px;
    border-left: 3px solid black;
    border-top: 3px solid black;
}

.panarea-section .text title {
    margin-bottom: 35px;
}

.panarea-section .text .description {
    margin-bottom: 35px;
    font-size: 18px;
}

.panarea-section .text .button {
    text-transform: uppercase;
    color: white;
    background-color: #1665D8;
    padding: 15px 30px 15px 30px;
    font-family: MontserratBold;
    border-radius: 35px;
}

.panarea-section .image {
    text-align: right;
}

.panarea-section .image img {
    max-width: 100%;
    max-height: 480px;
}

.panarea-section .icons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 25px;
}

.panarea-section .icons .icon-container {
    display: inline-block;
    margin-left: 20px;
}

.panarea-section .icons .icon-container img {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 20px;
}

.panarea-section .icons .icon-container .point-container {
    position: relative;
}

.panarea-section .icons .icon-container .point {
    background-color: #1665D8;
    border-radius: 50%;
    display: inline-block;
    height: 8.68px;
    width: 8.68px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 25%);
}