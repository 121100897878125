.appointment-section {
    padding-bottom: 100px;
    margin-bottom: 50px;
}

.appointment-section .css-1rtg91x-MuiDateCalendar-root {
    box-shadow: 0 0 44px 0 rgba(0,0,0,0.22), 0 44px 44px 0 rgba(0,0,0,0.3);
}

.appointment-section .MuiButtonBase-root,
.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel,
.css-cyfsxc-MuiPickersCalendarHeader-labelContainer,
.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
    font-size: 20px !important;
}

.css-1t0788u-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
    min-height: 210px !important;
}

.css-1rtg91x-MuiDateCalendar-root {
    height: auto !important;
}
/*
.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root {
    padding: 25px !important;
}

.css-1rtg91x-MuiDateCalendar-root {
    width: auto;
}*/

.form-container h1 {
    padding: 0 0 45px 45px;
    position: relative;
    margin-bottom: 40px;
}

.form-container h1 .corner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border-bottom: 3px solid black;
    border-left: 3px solid black;
}

.form-container button {
    text-transform: uppercase;
    color: white;
    background-color: #1665D8;
    padding: 20px 45px 20px 45px;
    font-family: MontserratBold;
    border-radius: 35px;
}