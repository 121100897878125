.references-section {
    padding-bottom: 100px;
    margin-bottom: 50px;
}

.references-section .title-container {
    padding: 35px 0 0 45px;
    position: relative;
}

.references-section .title-container .corner {
    position: absolute;
    top: 0;
    left: 10px;
    width: 60px;
    height: 60px;
    border-left: 3px solid black;
    border-top: 3px solid black;
}

.references-section .title-container .title {
    margin-bottom: 50px;
}

.references-section .reference-pic {
    height: 270px;
    background-color: #D8D8D8;
    border-radius: 10px;
    margin-left: 45px;
}