.header-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #FAFAFA;
    z-index: 1000;
}

.header-container .home-link {
    cursor: pointer;
    font-size: 23.72px;
    text-decoration: none;
    color: #000000;
}

.header-container .home-link img {
    width: auto;
    height: 18.52px;
    vertical-align: baseline;
    margin-right: 3px;
}

.header-container nav.nav-for-larger-than-xs {
    display: flex;
    justify-content: space-between;
    padding: 35px 0 35px 0;
    align-items: center;
}

.header-container .hamburger-icon img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.header-container nav .menu-for-xs-screen {
    list-style: none;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 1s;
    padding-top: 80px;
    min-width: 150px;
    background-color: grey;
    height: 100vh;
}

.header-container nav .menu-for-xs-screen.visible {
    transform: translateX(0);
}

.header-container nav .menu-for-xs-screen li {
    cursor: pointer;
}

.header-container nav .menu-for-xs-screen li a {
    text-decoration: none;
    color: #000000;
}

.header-container nav .menu-for-xs-screen li a:hover {
    font-family: MontserratBold;
}

.header-container nav .menu-for-xs-screen li a.active {
    font-family: MontserratBold;
}

.header-container nav .menu-for-larger-than-xs-screen {
    list-style: none;
    margin: 0;
}

.header-container nav .menu-for-larger-than-xs-screen li {
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
}

.header-container nav .menu-for-larger-than-xs-screen li a {
    text-decoration: none;
    color: #000000;
}

.header-container nav .menu-for-larger-than-xs-screen li a:hover {
    font-family: MontserratBold;
}

.header-container nav .menu-for-larger-than-xs-screen li a.active {
    font-family: MontserratBold;
}