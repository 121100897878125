.textarea {
    border-radius: 35px;
    border: none;
    background-color: #FFFFFF;
    padding: 15px;
    font-size: 20px;
    width: 100%;
}

.textarea-control-error{
    padding-top: 3px;
    color: red;
    font-size: 20px;
}