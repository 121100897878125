.footer-container {
    background-color: #282730;
    padding-top: 60px;
    padding-bottom: 30px;
}

.footer-container .title {
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    font-size: 23.72px;
    margin-bottom: 50px;
}

.footer-container .title img {
    width: auto;
    height: 18.52px;
    vertical-align: baseline;
    margin-right: 3px;
}

.footer-container .menu-container a {
    text-decoration: none;
    color: #FFFFFF;
}

.footer-container .menu-container .copyright {
    color: #FFFFFF;
    font-size: 10px;
    text-align: center;
}